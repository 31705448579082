<template>
  <div v-if="state.canShow" class="input-groups">
    <div class="input-groups">
      <div class="input-groups-title">{{ state.title }}</div>
      <div class="description">{{ state.description }}</div>
      <QuestionnaireItemDirective
        v-bind="directives[0]"
      ></QuestionnaireItemDirective>
      <template v-if="shouldShowQ1">
        <QuestionnaireItemDirective
          v-bind="directives[1]"
          @change="copyAddress"
        ></QuestionnaireItemDirective>
        <p class="input-errors" v-if="realEstateQsMessage">
          {{ realEstateQsMessage }}
        </p>
        <template v-if="realEstateQsIsShow">
          <div class="input-group">
            <label>査定したい物件の住所をご入力ください</label>
            <text-input
              type="tel"
              name="q_realestate_postal"
              label="郵便番号"
              :rules="{ required: true, regex: /^\d{7}$/ }"
              v-model="data.q_realestate_postal"
              placeholder="例）1050011"
              @keyup="getAddr($event)"
              @input="update('q_realestate_postal')"
            >
              <div class="annotation">※ハイフンはつけないでください</div>
            </text-input>
            <text-input
              type="text"
              name="q_realestate_pref"
              label="都道府県"
              rules="required"
              v-model="data.q_realestate_pref"
              placeholder="自動入力"
              readonly
            >
              <div class="annotation">※郵便番号から自動入力されます</div>
            </text-input>
            <text-input
              type="text"
              name="q_realestate_addr1"
              label="市区町村"
              rules="required"
              v-model="data.q_realestate_addr1"
              placeholder="自動入力"
              readonly
            >
              <div class="annotation">※郵便番号から自動入力されます</div>
            </text-input>
            <text-input
              type="text"
              name="q_realestate_addr2"
              label="番地"
              rules="required"
              v-model="data.q_realestate_addr2"
              placeholder="例）2-11-11"
              @input="update('q_realestate_addr2')"
            ></text-input>
            <text-input
              type="text"
              name="q_realestate_addr3"
              label="マンション名・部屋番号"
              rules="dependency_string|max:128"
              v-model="data.q_realestate_addr3"
              placeholder="例）芝マンション301"
              @input="update('q_realestate_addr3')"
            >
              <div class="annotation">
                ※苗字と違う場合のみ◯◯様方とご記入ください<br />
                ※マンション・建物名がある場合は必ず部屋番号までご記入ください
              </div>
            </text-input>
          </div>
          <Field
            :rules="{ required: true, regex: /^([1-9]\d*|0)(\.\d+)?$/ }"
            name="q_realestate2"
            label="面積"
            v-slot="{ field, errors, meta }"
            v-model="data.q_realestate2"
            @input="update('q_realestate2')"
            as="div"
          >
            <div class="input-group" :class="{ ok: meta.valid }">
              <ul class="input-errors" v-if="errors.length > 0">
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
              <div class="input-inline flex-start">
                <label class="vertical-middle"
                  ><span class="status"></span>{{ q2Title }}</label
                >
                <div>
                  <input type="tel" v-bind="field" placeholder="例）100" />
                </div>
                <span class="vertical-middle left-margin">m<sup>2</sup></span>
              </div>
            </div>
          </Field>
          <QuestionnaireItemDirective
            v-bind="directives[2]"
          ></QuestionnaireItemDirective>
          <QuestionnaireItemDirective
            v-bind="directives[3]"
          ></QuestionnaireItemDirective>
          <QuestionnaireItemDirective
            v-if="realEstateShowLastMessage"
            v-bind="directives[4]"
          ></QuestionnaireItemDirective>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { reactive, computed, toRaw } from "vue";
import { useStore } from "vuex";
import { QUESTIONNAIRE_VUEX } from "@/util/questionnaire/constant";
import Yubinbango from "@/yubinbango-core";
import QuestionnaireItemDirective from "@/components/Common/QuestionnaireItemDirective";

export default {
  name: "RealEstate",
  components: {
    QuestionnaireItemDirective,
  },
  setup() {
    const store = useStore();
    const answers = store.getters[QUESTIONNAIRE_VUEX.GETTERS.ANSWER];

    // start: customエリア
    const update = (key) => {
      store.dispatch(QUESTIONNAIRE_VUEX.ACTIONS.ANSWER, {
        [key]: toRaw(data)[key],
      });
    };

    const dataObj = {};
    [
      "q_realestate2",
      "q_realestate_postal",
      "q_realestate_pref",
      "q_realestate_addr1",
      "q_realestate_addr2",
      "q_realestate_addr3",
      "realestate_sponsor_type",
    ].forEach((key) => {
      dataObj[key] = answers(key) ?? "";
    });

    const realEstateQsMessage = computed(() => {
      if (answers("q_realestate1") === "今は必要ない") {
        return "";
      }
      // 「査定したい住所を入力する」の場合は、査定したい住所が現住所とは限らないためエラーは出さない
      if (
        answers("q_realestate1") === "先ほど入力した住所と同じ" &&
        ["分譲マンション（賃貸）", "一戸建て（賃貸）"].includes(
          answers("q7") ?? ""
        )
      ) {
        return "申し訳ありませんが、賃貸物件は査定の対象外です";
      }
      return "";
    });
    const realEstateQsIsShow = computed(() => {
      if (answers("q_realestate1") === "今は必要ない") {
        clearRealEstateQsIsShow();
        return false;
      }
      if (realEstateQsMessage.value) {
        return false;
      }
      return true;
    });

    const clearRealEstateQsIsShow = () => {
      data.q_realestate2 = "";
      data.q_realestate3 = "";
      data.q_realestate_postal = "";
      data.q_realestate_pref = "";
      data.q_realestate_addr1 = "";
      data.q_realestate_addr2 = "";
      data.q_realestate_addr3 = "";
      // 「不動産に悩みがあれば教えて下さい」配列の要素の存在確認
      if (answers("q_realestate4")) {
        answers("q_realestate4").splice(0);
      }
      [
        "q_realestate2",
        "q_realestate3",
        "q_realestate_postal",
        "q_realestate_pref",
        "q_realestate_addr1",
        "q_realestate_addr2",
        "q_realestate_addr3",
      ].forEach((key) => {
        update(key);
      });
    };

    const realEstateShowLastMessage = computed(() => {
      if (data.realestate_sponsor_type === "nichiju_service") {
        if (
          ["今後検討する可能性がある", "少し興味がある"].includes(
            answers("q_realestate5")
          )
        ) {
          return false;
        }
      }
      return true;
    });
    const copyAddress = () => {
      const value = answers("q_realestate1");
      // 今後はこういうのもちゃんとidなどの意味のある識別子で比較する
      // ハードコーディングしすぎ
      if (value !== "先ほど入力した住所と同じ") return;
      // 先程入力した住所と同じ ときだけ自動入力する
      data.q_realestate_postal = answers("postal");
      data.q_realestate_pref = answers("pref");
      data.q_realestate_addr1 = answers("addr1");
      data.q_realestate_addr2 = answers("addr2");
      data.q_realestate_addr3 = answers("addr3");

      [
        "q_realestate_postal",
        "q_realestate_pref",
        "q_realestate_addr1",
        "q_realestate_addr2",
        "q_realestate_addr3",
      ].forEach((key) => {
        update(key);
      });
    };

    const data = reactive(dataObj);

    const clearShouldShowQ1 = () => {
      data.q_realestate1 = "";
      data.q_realestate2 = "";
      data.q_realestate3 = "";
      data.q_realestate_postal = "";
      data.q_realestate_pref = "";
      data.q_realestate_addr1 = "";
      data.q_realestate_addr2 = "";
      data.q_realestate_addr3 = "";
      // 「不動産に悩みがあれば教えて下さい」配列の要素の存在確認
      if (answers("q_realestate4")) {
        answers("q_realestate4").splice(0);
      }
      [
        "q_realestate1",
        "q_realestate2",
        "q_realestate3",
        "q_realestate_postal",
        "q_realestate_pref",
        "q_realestate_addr1",
        "q_realestate_addr2",
        "q_realestate_addr3",
      ].forEach((key) => {
        update(key);
      });
    };

    const checkShowQ1 = (checkValue) => {
      return checkValue === "今は考えていない";
    };

    const shouldShowQ1 = computed(() => {
      if (checkShowQ1(answers("q_realestate5"))) {
        clearShouldShowQ1();
      }
      return !["", "今は考えていない"].includes(answers("q_realestate5") ?? "");
    });

    const q2Title = computed(() => {
      const q7 = answers("q7") ?? "";
      let title = "";
      if (q7.includes("マンション")) {
        title = "専有";
      } else {
        title = "土地";
      }
      return `${title}面積(だいたいでOKです)`;
    });
    // end:customエリア

    return {
      data,
      update,
      shouldShowQ1,
      q2Title,
      realEstateQsIsShow,
      realEstateShowLastMessage,
      realEstateQsMessage,
      copyAddress,
      clearShouldShowQ1,
      checkShowQ1,
      clearRealEstateQsIsShow,
    };
  },
  props: {
    title: {
      type: [String, Object],
      required: false,
    },
    description: {
      type: [String, Object],
      required: false,
    },
    directives: {
      type: Array,
      required: false,
      default: () => [],
    },
    state: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  methods: {
    getAddr(event) {
      const postcode = event.target.value;
      if (postcode.length !== 7) {
        return;
      }

      let parent = this.$parent;
      do {
        if (parent.$refs.form) {
          break;
        }
      } while ((parent = parent.$parent));
      const form = parent.$refs.form;

      new Yubinbango(postcode, (addr) => {
        this.data.q_realestate_postal = postcode;
        this.data.q_realestate_pref = addr.region;
        this.data.q_realestate_addr1 = addr.locality + addr.street;

        [
          "q_realestate_postal",
          "q_realestate_pref",
          "q_realestate_addr1",
        ].forEach((key) => {
          form.validateField(key);
          this.update(key);
        });
      });
    },
  },
};
</script>
