<template>
  <div v-if="state.canShow">
    <Field
      :rules="state.rules"
      key="id"
      :name="name"
      :label="state.about"
      :modelValue="state.value"
      @update:modelValue="null"
      v-slot="{ errors, meta }"
      as="div"
    >
      <component
        :is="inputOf(type)"
        v-bind="$props"
        :modelValue="state.value"
        @update:modelValue="onInput"
        :name="name"
        :passed="meta.valid"
        :options="state.options"
        :text="state.text"
      >
        <template v-slot:error>
          <ul class="input-errors" v-if="!meta.valid">
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </template>
      </component>
      <div
        v-if="computedAnnotation"
        class="annotation"
        v-html="computedAnnotation"
      ></div>
    </Field>
  </div>
</template>

<script>
import { reactive, toRaw, computed } from "vue";
import { mapActions, useStore } from "vuex";
import {
  QUESTIONNAIRE_TYPE,
  QUESTIONNAIRE_VUEX,
} from "@/util/questionnaire/constant";
import { answerDefaultValue, judgeStatement } from "@/util/questionnaire/util";

export default {
  name: QUESTIONNAIRE_TYPE.QUESTIONNAIRE_ITEM_DIRECTIVE,
  setup(props) {
    const store = useStore();
    const answers = store.getters[QUESTIONNAIRE_VUEX.GETTERS.ANSWER];
    const defaultParams = {
      options: props.options,
      texts: props.texts,
    };

    const judge = () =>
      judgeStatement(
        QUESTIONNAIRE_TYPE.QUESTIONNAIRE_ITEM_DIRECTIVE,
        props.statements,
        defaultParams
      );

    const judged = judge();
    const state = reactive({
      text: "",
      ...judged,
      rules: { required: props.required, ...props.rules },
      about: props.about ?? props.question,
      value: answers(props.name) ?? answerDefaultValue(props.type),
    });

    // setAnsersされた時に自分の値だったら更新する
    store.subscribe((mutation) => {
      if (mutation.type === QUESTIONNAIRE_VUEX.MUTATIONS.SET_ANSWERS) {
        if (props.name in mutation.payload) {
          const value = mutation.payload[props.name];
          if (state.value !== value) {
            state.value = value;
          }
        }
        Object.assign(state, judge());
      }
    });

    const computedAnnotation = computed(
      () => state.annotation || props.annotation
    );

    return {
      state,
      computedAnnotation,
    };
  },
  methods: {
    ...mapActions("answer", ["answer"]),
    inputOf(type) {
      return this.$store.getters[QUESTIONNAIRE_VUEX.GETTERS.COMPONENT](type);
    },
    onInput(value) {
      this.state.value = toRaw(value);
      this.answer({ [this.$props.name]: value });
      this.$emit("input", value);
    },
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    question: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    about: {
      type: String,
      required: false,
    },
    annotation: {
      type: String,
      required: false,
    },
    params: {
      type: Object,
      required: false,
    },
    options: {
      type: Array,
      required: false,
    },
    texts: {
      type: Array,
      required: false,
    },
    statements: {
      type: Array,
      required: false,
      default: () => [],
    },
    rules: {
      type: [Object, String],
      required: false,
      default: () => {},
    },
  },
  emits: ["input"],
};
</script>
