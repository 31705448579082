<template>
  <div class="input-groups">
    <div>
      <div class="input-group" :class="{ ok: passed }">
        <label>
          <span class="status"></span>
          ペット(犬や猫など)を飼っていますか?（複数選択可）
        </label>
        <ul class="input-errors" v-if="shouldShowErrors && !passed">
          <li>{{ errors }}</li>
        </ul>
        <div class="checkbox-container">
          <label
            v-for="option in options"
            :key="option.value"
            class="checkbox-button"
          >
            <input
              type="checkbox"
              name="pets"
              :value="option.value"
              :checked="isChecked(option.value)"
              @input="onChange($event, option)"
            />
            <span class="checkbox-custom"></span>
            <span class="checkbox-label">{{ option.value }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { QUESTIONNAIRE_VUEX } from "@/util/questionnaire/constant";

export default {
  name: "Pet",
  setup() {
    const store = useStore();
    const shouldShowErrors = ref(false);

    const selectedPets = computed(
      () => store.getters[QUESTIONNAIRE_VUEX.GETTERS.ANSWER]("q_pet1") || []
    );

    const passed = computed(() => selectedPets.value.length > 0);

    const options = [
      { value: "犬", isOnly: false },
      { value: "猫", isOnly: false },
      { value: "その他", isOnly: false },
      { value: "飼っていない", isOnly: true },
    ];

    const isChecked = (option) => selectedPets.value.includes(option);

    const errors = computed(() => {
      if (selectedPets.value.length === 0) {
        return "ペット(犬や猫など)を飼っていますか?は必須です";
      }
      return null;
    });

    const onChange = (event, option) => {
      if (event.target.checked) {
        if (option.isOnly) {
          store.dispatch(QUESTIONNAIRE_VUEX.ACTIONS.ANSWER, {
            q_pet1: [option.value],
          });
        } else {
          store.dispatch(QUESTIONNAIRE_VUEX.ACTIONS.ANSWER, {
            q_pet1: [...selectedPets.value, option.value],
          });
          const onlyOption = options.find((opt) => opt.isOnly);
          store.dispatch(QUESTIONNAIRE_VUEX.ACTIONS.ANSWER, {
            q_pet1: selectedPets.value.filter(
              (pet) => pet !== onlyOption.value
            ),
          });
        }
      } else {
        store.dispatch(QUESTIONNAIRE_VUEX.ACTIONS.ANSWER, {
          q_pet1: selectedPets.value.filter((pet) => pet !== option.value),
        });
      }
      shouldShowErrors.value = true;
    };

    const validate = () => {
      shouldShowErrors.value = true;
      return selectedPets.value.length > 0;
    };

    return {
      passed,
      shouldShowErrors,
      options,
      isChecked,
      errors,
      onChange,
      validate,
    };
  },
};
</script>

<style lang="scss" scoped>
.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.checkbox-button {
  background: #f1f3f5;
  display: flex;
  align-items: center;
  width: calc(50% - 5px);
  padding: 8px;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  border: 1px solid #adb5bd;
}
.checkbox-custom {
  width: 16px;
  height: 16px;
  background: #fff;
  border: 2px solid #adb5bd;
  border-radius: 4px;
  margin-right: 12px;
  position: relative;
}
.checkbox-label {
  flex-grow: 1;
  font-size: 1em;
}
input[type="checkbox"] {
  display: none;
  &:checked + .checkbox-custom::after {
    content: "";
    position: absolute;
    left: 4px;
    top: 0px;
    width: 6px;
    height: 12px;
    border: solid #339af0;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
</style>
