<template>
  <div :class="{ ok: passed, 'input-group': true }">
    <label><span class="status"></span>{{ question }}</label>
    <slot name="error"></slot>
    <input :type="type" :class="{ ng: failed }" v-model="value" />
    <slot></slot>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  name: "TextInput",
  setup(props, { emit }) {
    const value = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit("update:modelValue", value);
      },
    });

    return {
      value,
    };
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    question: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    passed: {
      type: Boolean,
      required: false,
    },
    failed: {
      type: Boolean,
      required: false,
    },
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: ["update:modelValue"],
};
</script>
