<template>
  <!-- 以下は今後mdで書いたものをレンダリングできるようにしましょうね -->
  <div class="entry-notification">
    <div class="title">
      以下の事項をお読みいただき、同意の上応募してください。
    </div>
    <ul class="body">
      <li>
        ・本キャンペーンの応募には、カラダノートメールマガジンへのご登録の同意が必須となります。
      </li>
      <li>
        ・入力に誤りがあるとプレゼントをお届けできませんのでご注意ください。（なお、お宛名はご入力いただいた内容がそのまま印刷されます。）
      </li>
      <li>
        ・本キャンペーンの内容期間その他の事項は通知なしに中止終了する場合があります。
      </li>
      <li>
        ・応募者は「<a
          href="https://corp.karadanote.jp/privacy-handling"
          target="_blank"
          >カラダノートプライバシーポリシー</a
        >」の規定に従うものとします。
      </li>
      <li>
        ・各種アンケート結果を元に、カラダノートカスタマーデスクより電話でのご案内をさせていただくことがございます。
      </li>
      <li>
        ・保険についてのアンケート結果を元に、株式会社ニュートン・フィナンシャル・コンサルティングより資料のお届けや連絡がある場合がございます。
      </li>
      <li>
        ・応募者は「伊藤忠オリコ保険サービス株式会社：<a
          href="http://www.itochuiis.co.jp/privacy/"
          >個人情報保護方針</a
        >」「株式会社ニュートン・フィナンシャル・コンサルティング：<a
          href="https://www.newton-fc.com/principle/privacy-policy.html"
          >個人情報保護方針</a
        >」の規定に同意するものとします。
      </li>
      <li>
        ・応募者は「日本リアライズ株式会社：<a
          href="https://n-realize.co.jp/privacy/"
          target="_blank"
          >個人情報保護方針</a
        >」の規定に同意するものとします。
      </li>
      <li>
        ・応募者は「株式会社NHS：<a
          href="https://www.n-h-s.co.jp/privacy1"
          target="_blank"
          >個人情報保護方針</a
        >」の規定に同意するものとします。
      </li>
      <li>
        ・本キャンペーンで取得した個人情報は協賛企業並びに個人情報を預託する業務委託先（＊）に提供する場合があります。
        <ul>
          <li>
            （＊）個人情報を提供する協賛企業並びに個人情報を預託する業務委託先の名称
          </li>
          <li>提供する協賛企業：各キャンペーンページに掲載している企業</li>
          <li>
            預託する業務委託先：各キャンペーン申し込みフォームページ備考に記載している企業
          </li>
        </ul>
      </li>
      <template v-if="shouldShowRealestateArea">
        <li>
          ・アンケート結果を元に、株式会社住まい専科マツオカより連絡がある場合がございます。
        </li>
        <li>
          ・応募者は株式会社住まい専科マツオカの<a
            href="https://www.sumai-senka.co.jp/privacy/"
            >個人情報保護方針の規定</a
          >に従うものとします。
        </li>
        <li>
          ・アンケート結果を元に、株式会社日住サービス又は株式会社ロケットより連絡がある場合がございます。
        </li>
        <li>
          ・応募者は株式会社日住サービスの<a
            href="https://2110.jp/privacy/policy.php"
            >個人情報保護方針</a
          >に従うものとします。
        </li>
      </template>
      <template v-if="getHousingCompanyName === 'lilife'">
        <li>
          株式会社LiLiFEは当社から提供された個人情報を以下の利用目的で使用します。
        </li>
        <li>・株式会社LiLiFEが提供するサービスまたは製品のご案内、ご提案</li>
        <li>
          なお、当社は株式会社LiLiFEより委託を受けて、同社の利用目的を公表するものです。
        </li>
        <li>
          住まいに関するアンケート結果を元に、株式会社LiLiFEより連絡がある場合がございます。
        </li>
      </template>
      <template v-if="getCoopCompanyName === 'palsystemKanagawa'">
        <li>
          ・応募者は「生活協同組合 パルシステム神奈川：個人情報保護方針：
          <a
            href="https://www.palsystem-kanagawa.coop/about/privacy/"
            target="_blank"
            >プライバシーポリシー</a
          >
          」の規定に従うものとします。
        </li>
      </template>
      <template v-if="getPowerCompanyName === 'stream'">
        <li>
          ・応募者は「株式会社 STREAM：
          <a
            href="https://stream-data.co.jp/wp-content/uploads/2023/04/privacy.pdf"
            target="_blank"
            >プライバシーポリシー</a
          >
          」の規定に従うものとします。
        </li>
      </template>
      <template v-if="getPowerCompanyName === 'wholelife'">
        <li>
          ・応募者は「株式会社 wholelife：
          <a href="https://wholelife.jp/privacy/" target="_blank"
            >個人情報保護方針</a
          >
          」の規定に従うものとします。
        </li>
      </template>
      <template v-if="getCoopCompanyName === 'palsystemShizuoka'">
        <li>
          ・応募者は「生活協同組合 パルシステム静岡：個人情報保護方針：
          <a href="https://palsystem-shizuoka.jp/privacy/" target="_blank"
            >プライバシーポリシー</a
          >
          」の規定に従うものとします。
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: "EntryNotice",
  props: {
    // 不動産関連の規約を表示するかどうかフラグ ※応募上限数を超えると非表示になる
    shouldShowRealestateArea: {
      type: Boolean,
      required: true,
      default: true,
    },
    getLongTermCareCompanyName: {
      type: [String, Boolean],
      required: true,
      default: false,
    },
    getHousingCompanyName: {
      type: [String, Boolean],
      required: true,
      default: false,
    },
    getCoopCompanyName: {
      type: [String, Boolean],
      required: true,
      default: false,
    },
    getPowerCompanyName: {
      type: [String, Boolean],
      required: true,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.entry-notification {
  font-size: 0.6rem;
  border: 1px solid #ddd;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 7rem;
  margin-top: 1.5rem;
  padding: 0.5rem;

  > .title {
    font-weight: bold;
  }

  > .body {
    list-style: none;
    padding: 0;
    margin-bottom: 0;

    ul {
      margin-bottom: 0;
      list-style: none;
      padding: 0;
    }
  }
}
</style>
