<template>
  <div class="host">
    <Form ref="form">
      <questionnaire :questionnaires="questionnaires"></questionnaire>
      <Pet ref="petComponent" @input-changed="handlePetInputChange" />
      <entry-notice
        :shouldShowRealestateArea="data.realEstateQsStateShouldShowArea"
        :getLongTermCareCompanyName="data.long_term_care_sponsor_type"
        :getHousingCompanyName="data.housing_sponsor_type"
        :getCoopCompanyName="data.coop_sponsor_type"
        :getPowerCompanyName="data.power_sponsor_type"
      />
      <div class="btn-area">
        <img
          src="https://mamab.jp/uploads/web_image/btn_agreenext.png"
          width="75%"
          @click="next"
          class="pointer"
          :class="{ disabled: isConnectingConfirm }"
        />
      </div>
    </Form>
  </div>
</template>

<style lang="scss" scoped>
.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>

<script>
import { reactive, ref, computed } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import { QUESTIONNAIRE_VUEX } from "@/util/questionnaire/constant";
import { scroll } from "@/util/util";
import Yubinbango from "@/yubinbango-core";
import MarketingCloud from "@/components/MarketingCloud.vue";
import Questionnaire from "@/components/Common/Questionnaire";
import TextInput from "@/components/Step2/TextInput";
import RealEstate from "@/components/Step2/RealEstate";
import Pet from "@/components/Step2/Pet";
import EntryNotice from "@/components/Step2/EntryNotice";

export default {
  name: "Step2",
  components: {
    Questionnaire,
    Pet,
    EntryNotice,
  },
  data() {
    return {
      isConnectingConfirm: false,
    };
  },
  setup() {
    const store = useStore();
    const answers = store.getters[QUESTIONNAIRE_VUEX.GETTERS.ANSWER];
    const questionnaires = store.getters["questionnaire/step2"];
    const petComponent = ref(null);

    store.dispatch(QUESTIONNAIRE_VUEX.ACTIONS.COMPONENT_REGISTER, {
      textInput: TextInput,
      realEstate: RealEstate,
    });

    store.subscribe((mutation) => {
      if (
        mutation.type === "answer/setAnswers" &&
        mutation.payload?.q_food_deliver2
      ) {
        store.dispatch("answer/answer", {
          q_food_deliver3: "",
          q_food_deliver4: "",
          q_food_deliver5: "",
          q_food_deliver6: "",
          q_food_deliver_sei: "",
          q_food_deliver_mei: "",
          q_food_deliver_sei_kana: "",
          q_food_deliver_mei_kana: "",
          q_food_deliver_postal: "",
          q_food_deliver_pref: "",
          q_food_deliver_address1: "",
          q_food_deliver_address2: "",
          q_food_deliver_address3: "",
          q_food_deliver_email: "",
          q_food_deliver_tel: "",
        });
      }
      if (
        mutation.type === "answer/setAnswers" &&
        !!mutation.payload?.q_food_deliver_postal
      ) {
        const postcode = mutation.payload.q_food_deliver_postal;
        if (postcode.length !== 7) {
          return;
        }

        new Yubinbango(postcode, (addr) => {
          store.dispatch("answer/answer", {
            q_food_deliver_pref: addr.region,
            q_food_deliver_address1: addr.locality + addr.street,
          });
        });
      }
    });

    const data = reactive({
      realEstateQsStateShouldShowArea: true,
      long_term_care_sponsor_type: answers("long_term_care_sponsor_type") ?? "",
      housing_sponsor_type: answers("housing_sponsor_type") ?? "",
      coop_sponsor_type: answers("coop_sponsor_type") ?? "",
      power_sponsor_type: answers("power_sponsor_type") ?? "",
    });

    // データを取得
    axios
      .post("/step2", store.getters[QUESTIONNAIRE_VUEX.GETTERS.ALL])
      .then((res) => {
        if (!res.data.realestateSponsorType) {
          data.realEstateQsStateShouldShowArea = false;
        }
        data.long_term_care_sponsor_type = res.data.longTermCareSponsorType;
        data.housing_sponsor_type = res.data.housingSponsorType;
        data.coop_sponsor_type = res.data.coopSponsorType;
        data.power_sponsor_type = res.data.powerSponsorType;

        store.dispatch(QUESTIONNAIRE_VUEX.ACTIONS.ANSWER, {
          realestate_sponsor_type: res.data.realestateSponsorType,
          housing_sponsor_type: res.data.housingSponsorType,
          coop_sponsor_type: res.data.coopSponsorType,
          power_sponsor_type: res.data.powerSponsorType,
          should_show_long_term_care_area: res.data
            .longTermCareSponsorLimitExceeded
            ? null
            : "show",
          long_term_care_sponsor_type: res.data.longTermCareSponsorType,
        });
      });

    const isPetValid = computed(() => petComponent.value?.validate());

    return {
      data,
      answers,
      questionnaires,
      isPetValid,
    };
  },
  mixins: [MarketingCloud],
  methods: {
    async next() {
      if (this.isConnectingConfirm) {
        return;
      }
      this.isConnectingConfirm = true;

      this.mcStep2Next(); // トラッキング(MarketingCloud)

      const formValid = await this.$refs.form.validate();
      const petValid = this.$refs.petComponent.validate();

      if (formValid.valid && petValid) {
        this.$router.push("confirm");
      } else {
        this.isConnectingConfirm = false;
        this.$nextTick(() => {
          scroll(".input-errors");
        });
      }
    },
  },
};
</script>
