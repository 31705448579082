<template>
  <template v-if="canShow">
    <QuestionnaireItem
      v-for="questionnaire in questionnaires.list"
      :key="questionnaire.title"
      v-bind="questionnaire"
    ></QuestionnaireItem>
  </template>
</template>

<script>
import { useStore } from "vuex";
import QuestionnaireItem from "./QuestionnaireItem";
import {
  QUESTIONNAIRE_TYPE,
  QUESTIONNAIRE_VUEX,
} from "@/util/questionnaire/constant";
import { judgeStatement } from "@/util/questionnaire/util";
import { Answers } from "@/util/questionnaire/global";

export default {
  name: QUESTIONNAIRE_TYPE.QUESTIONNAIRE,
  setup(props) {
    if (Answers.all === null) {
      const store = useStore();
      Answers.all = store.getters[QUESTIONNAIRE_VUEX.GETTERS.ALL];
    }

    const judged = judgeStatement(
      QUESTIONNAIRE_TYPE.QUESTIONNAIRE,
      props.questionnaires.statements ?? []
    );

    return {
      ...judged,
    };
  },
  components: {
    QuestionnaireItem,
  },
  props: {
    questionnaires: {
      type: Object,
      required: true,
    },
  },
};
</script>
