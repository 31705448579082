<template>
  <template v-if="state.canShow">
    <div class="input-groups" v-if="type === ''">
      <img :src="computedImage" v-if="computedImage" />
      <div class="input-groups-annotation">{{ state.annotation }}</div>
      <div class="input-groups-title" v-html="state.title"></div>
      <div class="description">{{ state.description }}</div>
      <QuestionnaireItemDirective
        v-for="directive in directives"
        :key="directive.name"
        v-bind="directive"
      >
      </QuestionnaireItemDirective>
    </div>
    <component
      v-if="type !== ''"
      :is="inputOf(type)"
      :state="state"
      v-bind="$props"
    ></component>
  </template>
</template>

<script>
import { reactive, computed } from "vue";
import QuestionnaireItemDirective from "./QuestionnaireItemDirective";
import {
  QUESTIONNAIRE_TYPE,
  QUESTIONNAIRE_VUEX,
} from "@/util/questionnaire/constant";
import { judgeStatement } from "@/util/questionnaire/util";
import { useStore } from "vuex";

export default {
  name: QUESTIONNAIRE_TYPE.QUESTIONNAIRE_ITEM,
  setup(props) {
    const store = useStore();
    const defaultParams = {
      title: props.title,
      description: props.description,
      annotation: props.annotation,
    };

    const judge = () =>
      judgeStatement(
        QUESTIONNAIRE_TYPE.QUESTIONNAIRE_ITEM,
        props.statements,
        defaultParams
      );

    const judged = judge();
    const state = reactive(judged);

    // setAnswerされた時に再判定する
    store.subscribe((mutation) => {
      if (mutation.type === QUESTIONNAIRE_VUEX.MUTATIONS.SET_ANSWERS) {
        Object.assign(state, judge());
      }
    });

    const computedImage = computed(() => state.image || props.image);

    return {
      state,
      computedImage,
    };
  },
  components: {
    QuestionnaireItemDirective,
  },
  methods: {
    inputOf(type) {
      return this.$store.getters[QUESTIONNAIRE_VUEX.GETTERS.COMPONENT](type);
    },
  },
  props: {
    title: {
      type: String,
      required: false,
      default: () => null,
    },
    description: {
      type: String,
      required: false,
      default: () => null,
    },
    annotation: {
      type: String,
      required: false,
      default: () => null,
    },
    directives: {
      type: Array,
      required: false,
      default: () => [],
    },
    image: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: () => "",
    },
    statements: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
};
</script>
