import { QUESTIONNAIRE_TYPE } from "./constant";
import jsonLogic from "json-logic-js";
import { Answers } from "./global";

/**
 *
 * @param {*} type
 * @returns
 */
function answerDefaultValue(type) {
  switch (type) {
    case "checkbox":
    case "multiSelect":
      return [];
    default:
      return "";
  }
}

/**
 * paramsはoptionやtitleなどのdefault値が入る想定
 * @param {*} type
 * @param {*} statements
 * @param {*} defaultParams
 * @returns
 */
function judgeStatement(type, statements, defaultParams = {}) {
  const result = {
    canShow: true,
    ...defaultParams,
  };

  if (!Array.isArray(statements) || statements.length === 0) {
    return result;
  }

  const actions = ["Display"];

  // analyzeConditionを使って各actionごとに評価する
  switch (type) {
    case QUESTIONNAIRE_TYPE.QUESTIONNAIRE:
      // Questionnaireの場合、現時点ではやることなし
      break;
    case QUESTIONNAIRE_TYPE.QUESTIONNAIRE_ITEM:
      // QuestionnaireItemの場合
      actions.push("ReplaceTitle", "ReplaceDescription", "ReplaceImage");
      break;
    case QUESTIONNAIRE_TYPE.QUESTIONNAIRE_ITEM_DIRECTIVE:
      // QuestionnaireItemDirectiveの場合
      actions.push(
        "ReplaceOptions",
        "RejectOptions",
        "ReplaceText",
        "ReplaceAnnotation"
      );
      break;
  }

  // 比較しやすいように小文字化
  for (let i = 0; i < actions.length; i++) {
    actions[i] = actions[i].toLowerCase();
  }

  for (const statement of statements) {
    // 条件が設定されていなければ何もしない
    if (!statement.conditions) {
      continue;
    }

    const action = statement.action.toLowerCase();
    // 実行可能actionでなければ無視する
    if (!actions.includes(action)) {
      continue;
    }
    const judgeResult = jsonLogic.apply(statement.conditions, Answers.all);
    // displayだけ判定結果を入れる
    if (action === "display") {
      result.canShow = judgeResult;
      continue;
    }

    // display以外は判定結果がtrueの時のみ処理させるため、ここで弾く
    if (!judgeResult) {
      continue;
    }

    // 比較のために全部小文字化しているので、小文字でcase比較
    // ReplaceTitle replaceTitleなどの表記揺らぎを吸収する
    switch (action) {
      case "replacetitle":
        // resourceの内容でタイトルを置き換える
        result.title = statement.resource;
        break;
      case "replacedescription":
        // resourceの内容でdescriptionを置き換える
        result.description = statement.resource;
        break;
      case "replaceannotation":
        // resourceの内容でannotationを置き換える
        result.annotation = statement.resource;
        break;
      case "replaceoptions":
        // resourceの内容で置き換える
        result.options = statement.resource;
        break;
      case "rejectoptions":
        // resourceに含んでいるものを弾く
        result.options = result.options.filter(
          (option) => !statement.resource.includes(option.label)
        );
        break;
      case "replacetext":
        // resourceの内容で置き換える
        result.text = statement.resource;
        break;
      case "replaceimage":
        // resourceの内容で置き換える
        result.image = statement.resource;
        break;
    }
  }

  return result;
}

export { judgeStatement, answerDefaultValue };
